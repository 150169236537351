@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 16px;
}

body,
html {
  background-color: #000;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

strong {
  font-weight: 700;
}

.mobile {
  display: none !important;
}

.desktop {
  display: block !important;
}

@media (max-width: 1024px) {
  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }
}

.vjs-text-track-display div {
  font-size: 1.1em;
  line-height: 150%;
  padding: 8px;
}
